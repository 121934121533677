import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Image from '../../../components/Image';

const ShanzaSami = () => (
  <Layout title="Alumni Spotlight - Shanza Sami" className="alumni-center bios">
    {/* Alumni Profile */}
    <Section className="alumni">
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../overview">Overview</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../alumni-spotlight">Alumni Spotlight</Link>
          </li>
          <li className="breadcrumb active">Shanza Sami</li>
        </ul>
        <Row>
          <Column size={7} offset={1} className="alumni__profile-left pr-2">
            <h2 className="alumni__profile-greeting">Meet Alumni:</h2>
            <h1 className="alumni__profile-title">Shanza Sami</h1>
            <h3 className="alumni__profile-type">
              2022 Young Scientist Challenge Finalist
            </h3>
            <h4 className="alumni__profile-project">
              Since participating in the 3M Young Scientist Challenge, Shanza
              has continued to work on her innovation, Pura Aurem. She has begun
              additional scientific pursuits, and has an interest in journalism.
            </h4>
            <h2 className="alumni__profile-question">
              What have you been up to since participating in the 3M Young
              Scientist Challenge?!
            </h2>
            <p>
              I have continued cultivating my passion for STEM through entering
              other research and academic science competitions. I presented at
              the State-wide Junior Science and Humanities Symposium (JSHS),
              earning 1st place and getting the opportunity to orally present my
              research at the National Junior Science and Humanities Symposium
              (NJSHS) in Virginia Beach, VA. I also participated in my regional
              and state science fairs, earning numerous titles and special
              awards, including being 1st place in Physical Sciences, 1st place
              in Environmental Sciences, 1st place in the Physical Sciences Oral
              SEMINAR Presentation, and being declared the Grand Champion in my
              state. I was given the opportunity to share my research as a
              finalist in the 2023 Regeneron ISEF competition in Dallas, Texas.
              Additionally, I have participated in business pitches for my
              product, being selected as a finalist for the Jacobson Institute
              Innovator Competition.
            </p>
            <h2 className="alumni__profile-question">
              Are you involved in any school teams or clubs, if so which ones?
            </h2>
            <p>
              I am involved in my school orchestra, where I play the violin, in
              addition to playing the piano outside of school. Aside from music,
              I enjoy participating in Science Olympiad, Speech and Debate,
              Student Government, Technology Student Association, and the
              Cadenza Music Club. I am also involved in my school publication,
              the West Side Story, where I write articles as a reporter.
            </p>
            <h2 className="alumni__profile-question">
              Did you continue working on your 3M YSC innovation? If yes, how
              far did you take your research and/or are you still working it,
              and do you have any progress updates that you can share
              (publication, patent, inspiration for another's work, etc)?
            </h2>
            <p>
              With regards to research, I continued enhancing my progress on my
              3MYSC innovation, Pura Aerem, through conducting more tests on a
              wider range of gas-powered vehicles, while also gathering SEM
              images of the filtration material to correlate structural
              integrity and filtration efficiency to the structure of the
              filters. Additionally, I conducted Computational Fluid Dynamic
              (CFD) simulations on GeoDict software by inputting properties of
              the filtration layers to determine any limitations with
              backpressure or filtration efficiency, while further optimizing
              the properties of the layers. I have also filed for a provisional
              patent to protect my intellectual property.
            </p>
            <h2 className="alumni__profile-question">
              You’ve stayed in contact with your 3M mentor, Patrick Zimmerman.
              Anything you’d like to share about your experience working with a
              3M mentor and staying in touch with your mentor after the
              Challenge?
            </h2>
            <p>
              I have continued to foster a great relationship with my 3M mentor,
              Dr. Zimmerman, since the 3MYSC. Aside from us giving life updates
              through text messages, Dr. Zimmerman visited me in Iowa City this
              summer, to receive updates on my life and research pursuits. Dr.
              Zimmerman has continued to provide amazing advice even beyond the
              3M Young Scientist Challenge.
            </p>
            <h2 className="alumni__profile-question">
              What advice do you have for a student who is considering entering
              the 3M Young Scientist Challenge? How would you say 3MYSC has
              impacted your life?
            </h2>
            <p>
              The 3MYSC was an amazing experience – a research competition that
              exposed me to a network of like-minded, curious, and intellectual
              peers who offered a plethora of knowledge in numerous disciplines.
              I found that the 3MYSC was an extremely valuable experience to
              develop effective research skills, troubleshoot to overcome
              obstacles, and create new connections. To any students considering
              entering the 3M Young Scientist Challenge – take the leap and
              create that two-minute video! The 3MYSC experience is most
              definitely worth it.
            </p>
          </Column>
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="global__profile-image">
                <Image filename="Sami-Shanza-2022-Finalist-Quote.png" />
              </div>
              <div className="global__profile-content">
                <h4 className="quote">
                  “To any students considering entering the 3M Young Scientist
                  Challenge – take the leap and create that two-minute video!
                  The 3MYSC experience is most definitely worth it.”
                </h4>
                <h3>Shanza Sami</h3>
                <p>2022 Finalist</p>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Related Alumni Profiles */}
    <Section className="related-profiles">
      <Container>
        <Row>
          <Column fullwidth>
            <h2>Related Alumni</h2>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Katie-Lampo-Alumni-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2017 Finalist</span>
                <h3>Kathryn Lampo</h3>
                <p>
                  Kathryn is currently a sophomore at Columbia University
                  pursuing a B.S. in Mechanical Engineering with a minor in
                  Applied Physics. She’s also interested in aerospace
                  engineering!
                </p>
                <Link to="../katie-lampo" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Kaien-Yang-Alumni-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2016 Finalist</span>
                <h3>Kaien Yang</h3>
                <p>
                  Kaien is a sophomore at Standford University majoring in
                  Computer Science with an AI track. He works with robots and
                  machine learning, and plays for Stanford’s club tennis team.
                </p>
                <Link to="../kaien-yang" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="profile-card box-shadow hover imagezoom">
              <div className="profile-card__image">
                <Image filename="Nicholas-LaJoie-Alumni-Card.jpg" />
              </div>
              <div className="profile-card__content">
                <span>2009 Finalist</span>
                <h3>Nicholas LaJoie</h3>
                <p>
                  Nicholas studied Computer Engineering in college and is
                  currently pursuing a career in technology. He plays the drums
                  and has worked in filmmaking.
                </p>
                <Link to="../nicholas-lajoie" className="text-20 text-bold">
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default ShanzaSami;
